import React from "react";
import Layout from "../component/layout";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import HomeImage from "../images/custom-manufacturer-home-image.png";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import BorderCards from "../component/BorderCards";
import TimelineV2Component from "../component/TimelineV2Component";
import CardsComponent from "../component/CardsComponent";
import Icon from "../images/card-icon.png";
import ItProsConsComponent from "../component/Services/IT/ItProsConsComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import AccordionComponent from "../component/accordionComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import FooterComponent from "../component/footerComponent";

const ServiceCustomManufacturing = () => {
  const seo = {
    title: "Custom Manufacturing Software Services & Solutions | Motomtech",
    metaDesc:
      "Your unique manufacturing business can benefit from Motomtech’s end-to-end custom design and developed software solutions.",
  };

  const cards = [
    {
      icon: Icon,
      title: "Compliance Regulations:",
      description:
        "Our solutions ensure your manufacturing processes remain compliant with industry regulations, reducing risk and increasing trust in your operations.",
    },
    {
      icon: Icon,
      title: "Video Surveillance: ",
      description:
        "We can integrate video surveillance capabilities into your systems, enhancing security and enabling real-time monitoring of your manufacturing processes.",
    },
    {
      icon: Icon,
      title: "Productivity Reports: ",
      description:
        "Gain insight into your operations with comprehensive productivity reports. Our software can track, analyze, and present data in a meaningful way, enabling you to make informed decisions.",
    },
    {
      icon: Icon,
      title: "Custom CRM:",
      description:
        "Our custom CRM solutions help you manage customer relationships more effectively, improving customer satisfaction and driving sales growth.",
    },
    {
      icon: Icon,
      title: "Material Tracking:",
      description:
        "With our software solutions, you can track materials throughout the production process, ensuring efficient utilization and minimizing waste.",
    },
    {
      icon: Icon,
      title: "Forecasting:",
      description:
        "Our solutions offer robust forecasting tools, helping you predict future demand and plan accordingly to optimize inventory and production schedules.",
    },
    {
      icon: Icon,
      title: "Utilization:",
      description:
        "We ensure efficient use of resources through utilization tracking, helping you reduce costs and increase productivity.",
    },
    {
      icon: Icon,
      title: "Inventory Management:",
      description:
        "Our software solutions streamline inventory management, helping you maintain optimal stock levels, reduce carrying costs, and improve order fulfillment.",
    },
    {
      icon: Icon,
      title: "Production Control Systems:",
      description:
        "We provide production control systems that help you manage and optimize your production lines, ensuring timely, cost-effective output.",
    },
    {
      icon: Icon,
      title: "Business Process Automation:",
      description:
        "Automate repetitive tasks, streamline workflows, and increase operational efficiency with our business process automation solutions.",
    },
    {
      icon: Icon,
      title: "APIs & Integrations:",
      description:
        "We offer seamless integration with your existing systems, ensuring a unified and efficient operational environment.",
    },
    {
      icon: Icon,
      title: "Product Configurators:",
      description:
        "Our software includes product configurators that allow for custom product design, enhancing customer satisfaction and fostering innovation.",
    },
    {
      icon: Icon,
      title: "Custom Portals:",
      description:
        "We design custom portals that provide a centralized platform for business processes, improving collaboration, transparency, and control.",
    },
  ];

  const arr1 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Discovery/Strategy:
        </p>
      ),
      description:
        "The first step is to gain a deep understanding of your startup's vision, objectives, and challenges. We conduct thorough research and engage in collaborative discussions to identify your specific needs and develop a strategic plan for your custom manufacturing software solution.",
      number: "1",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">Design: </p>
      ),
      description:
        "Using the insights gathered during the discovery phase, our team designs a blueprint for your software solution. We prioritize user experience and operational efficiency, ensuring the software is easy to use and perfectly aligned with your business processes.",
      number: "2",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">Development:</p>
      ),
      description:
        "Once the design is finalized, our developers start building your custom manufacturing software. We employ agile methodologies to ensure that the development process is flexible and efficient, allowing for changes and enhancements along the way.",
      number: "3",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">Integration:</p>
      ),
      description:
        "After the software is developed, we integrate it with your existing systems. This seamless integration ensures that your new software works in harmony with your current operations, minimizing disruption and maximizing efficiency.",
      number: "4",
    },
  ];
  const arr2 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          QA and Testing:
        </p>
      ),
      description:
        "To ensure the software is reliable and performs optimally, we conduct rigorous quality assurance tests and make necessary adjustments based on the results. This process helps ensure that your software is bug-free and ready for deployment.",
      number: "5",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Data Migration:
        </p>
      ),
      description:
        "If necessary, we assist with data migration from your old systems to the new software. We ensure this process is smooth and secure, with minimal downtime and zero data loss.",
      number: "6",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Support and Maintenance:
        </p>
      ),
      description:
        "Once the software is deployed, we provide ongoing support and maintenance to ensure it continues to serve your needs effectively. This includes regular updates, troubleshooting, and enhancements as your startup grows and evolves.",
      number: "7",
    },
  ];

  const questions1 = [
    {
      title: "What types of software does the manufacturing business require?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            Manufacturing businesses often require a variety of software types
            to manage different aspects of their operations effectively. One of
            the most essential types is Enterprise Resource Planning (ERP)
            software, which integrates all facets of an operation, including
            product planning, development, manufacturing processes, sales, and
            marketing. This enables businesses to have a cohesive overview of
            their operations, improving decision-making and efficiency.
          </p>
          <p className="accordion-section__card-desc">
            Manufacturing businesses also typically require Customer
            Relationship Management (CRM) software to manage customer
            interactions and enhance customer service. Material Requirements
            Planning (MRP) software is another crucial tool for managing
            inventory and scheduling production processes. Quality Control (QC)
            software helps maintain product standards, while Supply Chain
            Management (SCM) software assists in managing and optimizing the
            flow of goods and materials. Additionally, Business Intelligence
            (BI) software can be used to analyze business data and provide
            actionable insights. Depending on the specific needs of a
            manufacturing business, other types of software such as Product
            Lifecycle Management (PLM) software, Manufacturing Execution System
            (MES) software, and Human Resources (HR) software may also be
            required.
          </p>
        </>
      ),
      eventKey: 1,
    },
  ];

  const questions2 = [
    {
      title:
        "Should I develop a custom software, or buy an off-the-shelf option?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            The decision between developing custom software and buying an
            off-the-shelf solution depends on your business's specific needs and
            resources. Off-the-shelf software can be a good choice if it meets
            your needs exactly, is cost-effective, and if your processes align
            with the software's design. It's typically faster to implement, as
            it's already developed and ready to use.
          </p>
          <p className="accordion-section__card-desc">
            However, custom software development offers several significant
            advantages. It's tailored to your business's unique needs and can be
            adapted as your business grows or changes. While the initial
            development cost may be higher, custom software can provide a better
            return on investment in the long term, as it's designed to improve
            the efficiency and effectiveness of your specific operation.
          </p>
          <p className="accordion-section__card-desc">
            Moreover, with custom software, you typically have more control over
            the data security aspect, which can be crucial in industries
            handling sensitive data. Also, you can decide on the specific
            features you want to focus on, potentially creating a competitive
            advantage. Ultimately, the decision should be based on a thorough
            analysis of your business requirements, budget, and long-term
            strategic goals.
          </p>
        </>
      ),
      eventKey: 2,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={"Custom Manufacturing Software Services"}
        homeDescription={
          "Motomtech takes your unique and complex manufacturing needs into a simple to use custom software. With our custom software solutions you can streamline workflows, improve management capabilities, centralize reports, and much more."
        }
        homeImage={HomeImage}
        homeButton={"Request our services"}
        isGatsby={false}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={
          "A Custom Manufacturing Software Built To Address Your Every Need"
        }
        description1={
          "Choosing us for your manufacturing software needs is a strategic decision that will revolutionize the way you manage your business. With our custom manufacturing software, we don't just provide a generic solution; we meticulously craft a system that is tailor-made to suit your unique manufacturing needs. Understanding that out-of-the-box software solutions may not address all your requirements, we leverage our extensive knowledge and experience in the industry to design software that perfectly aligns with your specific operational challenges and goals."
        }
        description2={
          "Our custom manufacturing ERP software solutions offer exceptional flexibility and robust functionality to streamline your manufacturing processes. By seamlessly integrating all aspects of your operation, from supply chain management to production scheduling and quality control, our ERP solutions provide a single, unified platform to manage and optimize your business. The customizability of our ERP solutions ensures that the software evolves with your business, effortlessly accommodating changes and growth."
        }
        description3={
          "Trust is a fundamental aspect of any customer relationship. When it comes to entrusting your CRM needs to us, rest assured that we place great importance on reliability, security, and customer satisfaction. We continuously work to ensure that our software is secure and reliable, reducing any risk to your business. Furthermore, we offer dedicated support and training to ensure that you can make the most out of your investment in our software."
        }
        description4={
          "In essence, choosing us for your manufacturing software development needs means choosing a partner committed to delivering solutions that drive efficiency, enhance visibility, and foster growth in your manufacturing business. Our custom manufacturing software and ERP solutions are not just tools, but a strategic investment in your business's future. So, why settle for generic when you can have software designed and built precisely for you? With us, experience the difference of tailored solutions that truly understand and cater to your business needs."
        }
        showText={true}
      />
      <BorderCards
        borderCardsTitle={"Custom CRM Software Development Services"}
        borderCardsText1={"Custom Manufacturing Software Development:"}
        borderCardsText2={
          "We design and build bespoke manufacturing software solutions that are uniquely tailored to your specific operational needs. Our custom solutions ensure seamless integration of all manufacturing processes, enhancing efficiency, and driving productivity."
        }
        borderCardsText3={"Manufacturing IT Augmentation Services:"}
        borderCardsText4={
          "We offer IT augmentation services to reinforce your existing IT infrastructure. Our team of experts can help extend your capabilities, providing the additional technical expertise and manpower needed to ensure your manufacturing processes run smoothly and efficiently."
        }
        borderCardsText5={"Manufacturing Software Maintenance:"}
        borderCardsText6={
          "Our manufacturing software maintenance services ensure your systems run optimally at all times. We provide regular updates, troubleshoot issues, and make necessary adjustments to ensure your software remains current and effective in meeting your operational needs."
        }
        borderCardsText7={"Custom ERP Solutions:"}
        borderCardsText8={
          "Our custom manufacturing ERP software solutions provide a unified platform for managing all aspects of your manufacturing business. From supply chain management to production scheduling and quality control, our ERP solutions are designed to improve efficiency and visibility across your operations."
        }
        borderCardsText9={"Manufacturing Digital Transformation:"}
        borderCardsText10={
          "We help modernize your manufacturing processes through digital transformation. By integrating advanced digital technologies into your operations, we can help you achieve improved operational efficiency, increased productivity, and enhanced customer experience."
        }
        borderCardsText11={"Manufacturing Software QA:"}
        borderCardsText12={
          "We offer comprehensive quality assurance (QA) services for manufacturing software. Our rigorous testing procedures ensure your software is reliable, secure, and performs at its best, providing you with peace of mind."
        }
      />
      <TimelineV2Component
        timelineTitle={
          "Why Trust Motomtech with your Manufacturing Software Needs?"
        }
        timelineDescription={
          "Motomtech is your trusted partner for custom manufacturing software solutions, offering dedicated teams that deliver high-quality, tailor-made software to enhance your operational efficiency. With our deep manufacturing industry experience, we understand your unique challenges and provide solutions specifically designed to meet those needs. Our global presence across various time zones ensures uninterrupted support, while our agile methodology and flexible hiring models provide quick, adaptable, and cost-effective solutions. With Motomtech, you're not just choosing a software provider; you're choosing a partner invested in your success."
        }
        timelineTextTitle1={<p>Dedicated Teams:</p>}
        timelineTextDescription1={
          "Our dedicated teams offer unparalleled commitment to your project. With a deep understanding of your business needs, they deliver custom manufacturing software solutions that enhance operational efficiency and productivity. You can count on our team to provide a seamless, cohesive experience from the initial consultation through to final software deployment and beyond."
        }
        timelineTextTitle2={<p>Manufacturing Experience:</p>}
        timelineTextDescription2={
          "With a wealth of experience in the manufacturing industry, we understand the unique challenges and intricacies that your business faces. This insight allows us to design and implement software solutions that address your specific needs, ensuring you gain a competitive edge in the manufacturing landscape."
        }
        timelineTextTitle3={<p>Time Zone Alignment:</p>}
        timelineTextDescription3={
          "We offer services across various time zones, ensuring you get the support you need when you need it. Our diverse global presence means we are always available to assist you, regardless of your geographical location or operational hours."
        }
        timelineTextTitle4={<p>Agile Methodology:</p>}
        timelineTextDescription4={
          "We employ an Agile development methodology, allowing us to deliver solutions quickly and efficiently while maintaining high-quality standards. This approach enables us to adapt to changes swiftly, ensuring your software stays relevant and effective in a rapidly evolving manufacturing environment."
        }
        timelineTextTitle5={<p>Flexible Hiring Models: </p>}
        timelineTextDescription5={
          "We offer flexible hiring models to meet your specific needs and budget. Whether you need a full-time team, part-time assistance, or project-based support, we can provide the right resources and expertise."
        }
      />
      <CardsComponent
        title={
          "Build A Custom Manufacturing Software for Your Unique Day-To-Day"
        }
        description={
          "Our custom manufacturing software solutions at Motomtech are designed not just to meet your needs, but to transform your business operations, delivering tangible results that drive growth and efficiency. Here's how our services can make a difference:"
        }
        cards={cards}
      />
      <div style={{ position: "relative", zIndex: "3" }}>
        <ItProsConsComponent
          title={"Our Manufacturing Software Development Process"}
          description={
            "At Motomtech, we follow a comprehensive and structured software development process, specifically tailored to meet the unique needs and challenges of startups in the manufacturing industry. Our process comprises the following key stages:"
          }
          arr1={arr1}
          arr2={arr2}
        />
      </div>
      <TechStackComponent />
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={"Frequently asked questions"}
        subtitle={"Here you can find some questions that our user ask te most."}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={"Jumpstart your startup with Motomtech"}
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceCustomManufacturing;
